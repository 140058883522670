<style>
.swal2-popup .swal2-icon {
  margin-left: 39% !important;
}
</style>
<template>
  <div class="card card-custom">
    <div class="card-body">
      <div class="fixed">
        <adv-search
          @search="advSearch"
          :is_date_range="true"
          :is_service="true"
          :is_location="true"
        />
        <hr />
        <div class="row" v-if="reports.length > 0 && !getLoadingData">
          <div class="col-md-12">
            <download-excel
              class="btn btn-primary ml-3"
              style="float:right"
              :data="reports"
              :worksheet="search.date_from + ' - ' + search.date_to"
              name="location-wise-receivable-report.xls"
            >
              <i class="test-white fa fa-download"></i> Download Excel
            </download-excel>
          </div>
        </div>
      </div>
      <hr v-if="reports.length > 0 && !getLoadingData" />
      <div class="row" v-if="reports.length > 0 && !getLoadingData">
        <div class="col-md-12">
          <v-table :columns="columns">
            <tr v-for="(item, index) in reports" :key="index">
              <td>{{ item.location_name }}</td>
              <td>{{ item.applicant }}</td>
              <td>{{ item.service }}</td>
              <td>{{ item.schedule_date }}</td>
              <!-- <td>{{ item.commission | toFixedTwoDigit }}</td> -->
              <td class="text-right">
                {{ item.amount | toFixedTwoDigit }}
              </td>
            </tr>
            <tr>
              <td colspan="4">
                <b>Grand Total</b>
              </td>
              <td class="text-right">
                <b> {{ grand_total | toFixedTwoDigit }}</b>
              </td>
            </tr>
          </v-table>
        </div>
      </div>
      <div class="row" v-if="reports.length < 1 && !getLoadingData">
        <div class="col-md-12 text-center">
          <h4>no report(s) found</h4>
        </div>
      </div>

      <div class="row" v-if="getLoadingData">
        <div class="col-md-12 text-center">
          <v-data-loader></v-data-loader>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import moment from "moment";
import {
  FETCH_CLIENTS,
  FETCH_SERVICES,
  FETCH_LOCATION_WISE_RECEIVABLE_REPORT
} from "@/core/services/store/actions.type";
import { SET_BREADCRUMB } from "@/core/services/store/mutations.type";
import vTable from "@/includes/table/Datatable";
import { mapGetters } from "vuex";
import vDataLoader from "@/components/frontend/spinner.vue";
import AdvSearch from "@/includes/search/AdvSearch";
// import * as PDF417 from "pdf417-generator";
import JsonExcel from "vue-json-excel";
export default {
  components: {
    vTable,
    vDataLoader,
    downloadExcel: JsonExcel,
    AdvSearch
  },
  computed: {
    ...mapGetters(["currentUser", "getClients", "getServices", "getCompanies"]),
    grand_total: function() {
      let total = 0;
      if (this.reports.length != 0) {
        this.reports.forEach(element => {
          total += element.amount;
        });
      }
      return total;
    },
    total_commission: function() {
      let total = 0;
      if (this.reports.length != 0) {
        this.reports.forEach(element => {
          total += element.commission;
        });
      }
      return total;
    }
  },
  filters: {
    toFixedTwoDigit(amount) {
      return parseFloat(amount).toFixed(2);
    }
  },
  data() {
    return {
      search: {
        service: "all",
        company: "all",
        date_from: "",
        date_to: "",
        service_status: "all",
        payment_status: "all"
      },

      getLoadingData: false,
      columns: [
        {
          name: "location",
          width: "",
          label: "Location",
          class: ""
        },
        {
          name: "Applicant Name",
          width: "",
          label: "Applicant Name",
          class: ""
        },
        {
          name: "Service Name",
          width: "",
          label: "Service Name",
          class: ""
        },

        {
          name: "Scheduled Date",
          width: "",
          label: "Scheduled Date",
          class: ""
        },

        {
          name: "amount",
          width: "",
          label: "Amount",
          class: "text-left"
        }
      ],
      breadCrumbs: [
        {
          id: 1,
          title: "Dashboard",
          route: "client.dashboard"
        },
        {
          id: 2,
          title: "Report",
          route: ""
        },
        {
          id: 2,
          title: "Location Wise Receivable Report",
          route: ""
        }
      ],
      reports: []
    };
  },

  mounted() {
    this.$store.dispatch(FETCH_CLIENTS);
    let serviceOutlet = this.currentUser.u.is_client ? "self" : "all";
    this.$store.commit(SET_BREADCRUMB, this.breadCrumbs);
    this.$store.dispatch(FETCH_SERVICES, serviceOutlet);
  },

  methods: {
    advSearch(value) {
      this.getLoadingData = true;
      this.$store
        .dispatch(FETCH_LOCATION_WISE_RECEIVABLE_REPORT, value)
        .then(res => {
          this.getLoadingData = false;
          this.reports = res.data;
        })
        .catch(err => {
          this.getLoadingData = false;
          this.msg = err.error;
        });
    },

    refreshData() {
      this.pagination = {
        total_records: 0,
        total_page: 0,
        current_page: 1,
        per_page_data: 15,
        received_per_page_data: 15
      };
    },

    statusColor(status) {
      if (status == "P") {
        return "warning";
      }
      if (status == "C") {
        return "success";
      }
      if (status == "U") {
        return "info";
      }
      if (status == "Cancel") {
        return "danger";
      }
    },
    selectedPage(n) {
      this.pagination.current_page = n;
      //   this.appData();
    },
    nextPage() {
      this.pagination.current_page = this.pagination.current_page + 1;
      //   this.appData();
    },
    lastPage() {
      this.pagination.current_page = this.pagination.total_page;
      //   this.appData();
    },
    firstPage() {
      this.pagination.current_page = 1;
      //   this.appData();
    },
    previousPage() {
      this.pagination.current_page = this.pagination.current_page - 1;
      //   this.appData();
    }
  }
};
</script>
